import React, {useEffect, useRef, useState} from "react";
import {useOverrides, Override} from "@quarkly/components";
import {Text, Span, Box, Button, Icon, Section} from "@quarkly/widgets";
import {IoIosCheckmark} from "react-icons/io";
import {Input, Modal, Select, Space, Tooltip} from "antd";
import {CreditCardOutlined, GlobalOutlined, InfoCircleOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {useSearchParams} from "../hook/useSearchParams";
import {priceMonthly} from "../priceData";
import {useReferralCode} from "./ReferralHandler";

const blackFridaySmall35UA = () => {
    return null;
    // return <Image
    //     // style={{
    //     //     padding: '50px 0px 0px 0px',
    //     // }}
    //     src="https://cdzv.com/strategy_img/Image_2024-11-25_22-34-03.png"
    //     width={"100%"}
    //     preview={false}
    // />
}

const blackFridaySmall70UA = () => {
    return null;
    // return <Image
    //     // style={{
    //     //     padding: '50px 0px 0px 0px',
    //     // }}
    //     src="https://cdzv.com/strategy_img/Image_2024-11-25_22-09-53.png"
    //     width={"100%"}
    //     preview={false}
    // />
}

const defaultProps = {
    "padding": "50px 0 0 0",
    "background": "--color-white",
    "lg-margin": "0 0 0 0",
    "lg-padding": "50px 0 0px 0",
    "quarkly-title": "CdzvPricing",
    "box-shadow": "0 0 0 0 --color-lightD2",
    "id": "pricing"
};
const overrides = {
    "box": {
        "kind": "Box",
        "props": {
            "min-width": "300px",
            "min-height": "100px",
            "max-width": "1280px",
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "justify-content": "center",
            "text-align": "center",
            "background": "--color-white"
        }
    },
    "text": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 20px 0px",
            "font": "normal 600 46px/1.2 --fontFamily-sans",
            "text-align": "center",
            "md-margin": "0px 0px 50px 0px",
            "width": "700p",
            "align-self": "center",
            "color": "--dark",
            "sm-margin": "0px 0px 30px 0px",
            "children": "Почніть торгувати розумніше вже сьогодні"
        }
    },
    "text1": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 70px 0px",
            "text-align": "center",
            "font": "normal 300 20px/1.5 --fontFamily-sansTrebuchet",
            "width": "600px",
            "align-self": "center",
            "md-width": "100%",
            "color": "#53585b",
            "md-margin": "0px 0px 60px 0px",
            "sm-margin": "0px 0px 40px 0px",
            "lg-margin": "0px 0px 80px 0px"
        }
    },
    "box1": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "lg-width": "100%",
            "width": "1000px"
        }
    },
    "box2": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "display": "flex",
            "lg-margin": "0px 0 0px 0",
            "md-flex-direction": "column",
            "border-width": "1px",
            "lg-flex-direction": "column",
            "justify-content": "center"
        }
    },
    "box3": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "padding": "35px 35px 50px 35px",
            "border-color": "#26212d",
            "border-radius": "15px",
            "box-shadow": "0 4px 5px -1px rgba(0, 0, 0, 0.04)",
            "display": "flex",
            "flex-direction": "column",
            "background": "--color-grey",
            "border-width": "1px",
            "border-style": "solid",
            "position": "relative",
            "z-index": "1",
            "width": "33%",
            "lg-width": "100%",
            "lg-margin": "0px 0px 50px 0px",
            "lg-padding": "40px 40px 40px 40px",
            "lg-align-items": "stretch",
            "sm-padding": "30px 20px 30px 20px",
            "sm-margin": "0px 0px 30px 0px"
        }
    },
    "box4": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "sm-display": "flex",
            "sm-flex-direction": "column",
            "sm-align-items": "center",
            "display": "grid"
        }
    },
    "text2": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 8px 0px",
            "font": "normal 600 20px/1.5 Arial, sans-serif",
            "color": "--light",
            "lg-margin": "0px 0px 20px 0px",
            "sm-text-align": "center",
            "sm-margin": "0px 0px 10px 0px",
            "children": <>
                БЕЗКОШТОВНО<br/>
                <span style={{fontWeight: 300, fontSize: 14, color: "lightgreen"}}>без кредитної картки</span>
            </>
        }
    },
    "box5": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "align-items": "center",
            "margin": "0px 0px 8px 0px",
            "sm-flex-direction": "column",
            "flex-wrap": "wrap",
            "justify-content": "center"
        }
    },
    "text3": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 0px 0px",
            "font": "--headline3",
            "color": "#c1cbd1",
            "children": <>
                <Span
                    font="normal 300 41px/1.5 Arial, sans-serif"
                    // font="--headline2"
                    overflow-wrap="normal"
                    word-break="normal"
                    white-space="normal"
                    text-indent="0"
                    text-overflow="clip"
                    hyphens="manual"
                    user-select="auto"
                    pointer-events="auto"
                    color="--light"
                    margin="0px 0px 8px 0px"
                >
                    $0
                </Span>
            </>
        }
    },
    "text4": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 0px 0px",
            "font": "normal 400 18px/1.5 --fontFamily-sans",
            "color": "--white",
            "sm-text-align": "center",
            "border-color": "--color-white"
        }
    },
    "button": {
        "kind": "Button",
        "props": {
            "background": "--color-primary",
            "color": "--light",
            "border-color": "rgba(247, 251, 255, 0)",
            "border-radius": "8px",
            "font": "normal 400 17px/1.5 --fontFamily-sansHelvetica",
            "margin": "0px 0px 25px 0px",
            "sm-margin": "0px 0px 25px 0px",
            "hover-background": "--color-veles"
        }
    },
    "box6": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px"
        }
    },
    "box7": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "align-items": "center",
            "border-width": "0 0 1px 0",
            "border-style": "dashed",
            "border-color": "#393341",
            "margin": "0 0 6px 0"
        }
    },
    "icon": {
        "kind": "Icon",
        "props": {
            "category": "io",
            "icon": IoIosCheckmark,
            "size": "32px",
            "color": "--purple",
            "margin": "0px 8px 0px 0px"
        }
    },
    "text5": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "color": "--light",
            "children": <>
                CDZV toolkit{" "}
                <Span
                    overflow-wrap="normal"
                    word-break="normal"
                    white-space="normal"
                    text-indent="0"
                    text-overflow="clip"
                    hyphens="manual"
                    user-select="auto"
                    pointer-events="auto"
                    color="--secondary"
                >
                    Free&nbsp;
                </Span>
                <Tooltip
                    title="Безкоштовні модулі пропонують обмежену функціональність, щоб допомогти вам почати роботу. Усі стратегії в безкоштовному плані побудовані з використанням базових інструментів - ідеально для навчання і початкового торгового досвіду. Для отримання розширених можливостей і повної колекції стратегій перейдіть на наш платний тарифний план.">
                    <QuestionCircleOutlined/>
                </Tooltip>
            </>
        }
    },
    "text5_0": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "color": "--light",
            "text-decoration-line": "line-through",
            "children": <>
                Coin Rebalancer
            </>
        }
    },
    "text5_1": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "color": "--light",
            "text-decoration-line": "line-through",
            "children": <>
                SMART Grid PRO
            </>
        }
    },
    "box8": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "align-items": "center",
            "border-width": "0 0 1px 0",
            "border-style": "dashed",
            "border-color": "#393341",
            "margin": "0 0 6px 0"
        }
    },
    "icon1": {
        "kind": "Icon",
        "props": {
            "category": "io",
            "icon": IoIosCheckmark,
            "size": "32px",
            "color": "--purple",
            "margin": "0px 8px 0px 0px"
        }
    },
    "text6": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "color": "--light",
            "children": <>
                CDZV індикатори{" "}
                <Span color="--secondary">
                    Free
                </Span>
            </>
        }
    },
    "box9": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "align-items": "center",
            "border-width": "0 0 1px 0",
            "border-style": "dashed",
            "border-color": "#393341",
            "margin": "0 0 6px 0"
        }
    },
    "icon2": {
        "kind": "Icon",
        "props": {
            "category": "io",
            "icon": IoIosCheckmark,
            "size": "32px",
            "color": "--purple",
            "margin": "0px 8px 0px 0px"
        }
    },
    "text7": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "color": "--light",
            "children": <>
                Бектестування{" "}
                <Span
                    color="--secondary"
                    overflow-wrap="normal"
                    word-break="normal"
                    white-space="normal"
                    text-indent="0"
                    text-overflow="clip"
                    hyphens="manual"
                    user-select="auto"
                    pointer-events="auto"
                    font="18px/21.6px "
                >
                    Free
                </Span>
            </>
        }
    },
    "box10": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "align-items": "center",
            "margin": "0 0 6px 0",
            "border-width": "0 0 1px 0",
            "border-style": "dashed",
            "border-color": "#393341"
        }
    },
    "icon3": {
        "kind": "Icon",
        "props": {
            "category": "io",
            "icon": IoIosCheckmark,
            "size": "32px",
            "color": "--purple",
            "margin": "0px 8px 0px 0px"
        }
    },
    "text8": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "color": "--light",
            "children": <>
                Приклади стратегій{" "}
                <Span
                    overflow-wrap="normal"
                    word-break="normal"
                    white-space="normal"
                    text-indent="0"
                    text-overflow="clip"
                    hyphens="manual"
                    user-select="auto"
                    pointer-events="auto"
                    color="--secondary"
                >
                    Free
                </Span>
            </>
        }
    },
    "box11": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "align-items": "center",
            "margin": "0 0 6px 0",
            "border-width": "0 0 1px 0",
            "border-style": "dashed",
            "border-color": "#393341"
        }
    },
    "icon4": {
        "kind": "Icon",
        "props": {
            "category": "io",
            "icon": IoIosCheckmark,
            "size": "32px",
            "color": "--purple",
            "margin": "0px 8px 0px 0px"
        }
    },
    "text9": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "color": "--light",
            "text-decoration-line": "line-through",
            "children": <>
                Можливості копіювання{" "}
                {/*<Tooltip*/}
                {/*    title="In the free plan you have one connection to the crypto exchange and 3 bots to copy trades from our strategies.">*/}
                {/*    <QuestionCircleOutlined/>*/}
                {/*</Tooltip>*/}
            </>
        }
    },
    "box12": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "align-items": "center",
            "margin": "0 0 6px 0",
            "border-width": "0 0 1px 0",
            "border-style": "dashed",
            "border-color": "#393341"
        }
    },
    "icon5": {
        "kind": "Icon",
        "props": {
            "category": "io",
            "icon": IoIosCheckmark,
            "size": "32px",
            "color": "--purple",
            "margin": "0px 8px 0px 0px"
        }
    },
    "text10": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "color": "--light",
            "text-decoration-line": "line-through",
            "children": "Послуги та агенти ШІ"
        }
    },
    "box13": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "align-items": "center",
            "margin": "0 0 6px 0",
            "border-width": "0 0 1px 0",
            "border-style": "dashed",
            "border-color": "#393341"
        }
    },
    "icon6": {
        "kind": "Icon",
        "props": {
            "category": "io",
            "icon": IoIosCheckmark,
            "size": "32px",
            "color": "--purple",
            "margin": "0px 8px 0px 0px"
        }
    },
    "text11": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "color": "--light",
            "text-decoration-line": "line-through",
            "children": "Сервер Webhook"
        }
    },
    "box14": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "align-items": "center",
            "margin": "0 0 6px 0",
            "border-width": "0 0 1px 0",
            "border-style": "dashed",
            "border-color": "#393341"
        }
    },
    "icon7": {
        "kind": "Icon",
        "props": {
            "category": "io",
            "icon": IoIosCheckmark,
            "size": "32px",
            "color": "--purple",
            "margin": "0px 8px 0px 0px"
        }
    },
    "text12": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "color": "--light",
            "children": <>
                Спільнота трейдерів
            </>
        }
    },
    "box15": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "align-items": "center",
            "margin": "0 0 6px 0",
            "border-width": "0 0 1px 0",
            "border-style": "dashed",
            "border-color": "#393341"
        }
    },
    "icon8": {
        "kind": "Icon",
        "props": {
            "category": "io",
            "icon": IoIosCheckmark,
            "size": "32px",
            "color": "--purple",
            "margin": "0px 8px 0px 0px"
        }
    },
    "text13": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "color": "--light",
            "text-decoration-line": "line-through",
            "text-align": "left",
            "children": "Запити на модифікацію індикаторів"
        }
    },
    "box16": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "align-items": "center",
            "margin": "0 0 6px 0",
            "border-width": "0 0 1px 0",
            "border-style": "dashed",
            "border-color": "#393341"
        }
    },
    "icon9": {
        "kind": "Icon",
        "props": {
            "category": "io",
            "icon": IoIosCheckmark,
            "size": "32px",
            "color": "--purple",
            "margin": "0px 8px 0px 0px"
        }
    },
    "text14": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "color": "--light",
            // "text-decoration-line": "line-through",
            "children": "Підтримка клієнтів (ШІ)"
        }
    },
    "box17": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "padding": "35px 35px 60px 35px",
            "border-width": "1px",
            "border-style": "solid",
            "border-color": "#bec3ca",
            "border-radius": "15px",
            "box-shadow": "0 4px 5px -1px rgba(0, 0, 0, 0.04)",
            "display": "flex",
            "flex-direction": "column",
            "background": "--color-light",
            "position": "relative",
            "z-index": "2",
            "margin": "-40px -5px -40px -5px",
            "width": "33%",
            "lg-width": "100%",
            "lg-margin": "0px 0px 50px 0",
            "lg-padding": "40px 40px 40px 40px",
            "lg-align-items": "stretch",
            "sm-padding": "30px 20px 30px 20px",
            "sm-margin": "0px 0px 30px 0"
        }
    },
    "text15": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 0px 0px",
            "font": "normal 600 20px/1.5 Arial, sans-serif",
            "color": "--darkL1",
            "lg-margin": "0px 0px 20px 0px",
            "sm-text-align": "center",
            "sm-margin": "0px 0px 10px 0px",
            "children": "ALL-IN-ONE"
        }
    },
    "box18": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "sm-display": "flex",
            "sm-flex-direction": "column",
            "sm-align-items": "center",
            "sm-justify-content": "center",
            "display": "grid",
            "margin": "0px 0px 0px 0px"
        }
    },
    "box19": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "align-items": "center",
            "margin": "0px 0px 0px 0px",
            "sm-flex-direction": "column",
            "flex-wrap": "wrap",
            "justify-content": "center"
        }
    },
    "text16": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 0px 0px",
            "font": "normal 300 41px/1.5 Arial, sans-serif",
            "color": "#c1cbd1",
            "children": <Span
                font="Arial, sans-serif"
                overflow-wrap="normal"
                word-break="normal"
                white-space="normal"
                text-indent="0"
                text-overflow="clip"
                hyphens="manual"
                user-select="auto"
                pointer-events="auto"
                color="--darkL1"
                margin="0px 0px 0px 0px"
                // font-size="81"  // немного меньший размер
            >
                <Span
                    font="Arial, sans-serif"
                    color="red"  // зеленый цвет для экономии
                    font-size="18px"
                    margin="0px 0px 0px 0px"
                    font-weight="600"
                    display="block"
                >
                    {blackFridaySmall35UA()}
                    {/*We do have discounts, ask in the chat*/}
                </Span>
                <Span
                    font="normal 400 14px/1.5 Arial, sans-serif"
                    color="--veles"
                    overflow-wrap="normal"
                    word-break="normal"
                    white-space="normal"
                    text-indent="0"
                    text-overflow="clip"
                    hyphens="manual"
                    user-select="auto"
                    pointer-events="auto"
                    display="block"
                    margin="20px 0px 0px 0px"
                >
                    Щомісячно
                </Span>
                {/*<Span*/}
                {/*    font="normal 100 20px/1.5 Arial, sans-serif"*/}
                {/*    overflow-wrap="normal"*/}
                {/*    word-break="normal"*/}
                {/*    white-space="normal"*/}
                {/*    text-indent="0"*/}
                {/*    text-overflow="clip"*/}
                {/*    hyphens="manual"*/}
                {/*    user-select="auto"*/}
                {/*    pointer-events="auto"*/}
                {/*    // color="#808080"  // серый цвет для старой цены*/}
                {/*    margin="0px 0px 0px 0px"*/}
                {/*    text-decoration="line-through"  // перечеркивание*/}
                {/*    // font-size="21"  // немного меньший размер*/}
                {/*    display="block"*/}
                {/*    color="#2f2f2f"*/}
                {/*>*/}
                {/*    $99.99*/}
                {/*</Span>*/}
                <Span
                    font="normal 300 41px/1.5 Arial, sans-serif"
                    overflow-wrap="normal"
                    word-break="normal"
                    white-space="normal"
                    text-indent="0"
                    text-overflow="clip"
                    hyphens="manual"
                    user-select="auto"
                    pointer-events="auto"
                    // color="#808080"  // серый цвет для старой цены
                    margin="0px 0px -30px 0px"
                    // text-decoration="line-through"  // перечеркивание
                    // font-size="21"  // немного меньший размер
                    display="block"
                >
                    $150
                </Span>
                <span style={{fontSize: 14, color: "#e88894", fontWeight: 600}}>
                    <Tooltip
                        title={'Тільки для тих, хто платить кредитною карткою. В іншому випадку Ви можете підписатися на 15-денний пробний період за 20USDT.'}>
                        15-денний пробний період <CreditCardOutlined/> <QuestionCircleOutlined/>
                    </Tooltip>
                </span>
            </Span>
        }
    },
    "text17": {
        "kind":
            "Text",
        "props":
            {
                "margin":
                    "0px 0px 10px 0px",
                "font":
                    "normal 400 18px/1.5 --fontFamily-sans",
                "color":
                    "--darkL2",
                "sm-text-align":
                    "center",
                "children":
                    <>
                        {/*{" "}/{" "}*/}
                        <Span
                            font="normal 400 14px/1.5 --fontFamily-sans"
                            color="--veles"
                            overflow-wrap="normal"
                            word-break="normal"
                            white-space="normal"
                            text-indent="0"
                            text-overflow="clip"
                            hyphens="manual"
                            user-select="auto"
                            pointer-events="auto"
                        >
                            Щомісячно
                        </Span>
                    </>
            }
    },
    "button1": {
        "kind":
            "Button",
        "props":
            {
                "background": "--color-primary",
                "color": "--light",
                "border-color": "rgba(247, 251, 255, 0)",
                "border-radius": "8px",
                "font": "normal 400 17px/1.5 --fontFamily-sansHelvetica",
                "margin": "0px 0px 25px 0px",
                "sm-margin": "0px 0px 25px 0px",
                "hover-background": "--color-veles"
            }
    },
    "box20": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px"
        }
    },
    "box21": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "align-items": "center",
            "border-width": "0 0 1px 0",
            "border-style": "dashed",
            "border-color": "#bec3ca",
            "margin": "0 0 6px 0"
        }
    },
    "icon10": {
        "kind": "Icon",
        "props": {
            "category": "io",
            "icon": IoIosCheckmark,
            "size": "32px",
            "color": "--indigo",
            "margin": "0px 8px 0px 0px"
        }
    },
    "text18": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "color": "#4f5457",
            "children":
                <>
                    CDZV toolkit
                </>
        }
    },
    "text18_0": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "color": "#4f5457",
            "children":
                <>
                    Coin Rebalancer
                </>
        }
    },
    "text18_1": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "text-decoration-line": "line-through",
            "color": "#4f5457",
            "children":
                <>
                    SMART Grid PRO <Tooltip
                    title="Модуль SMART Grid PRO доступний як частина річного платного плану.">
                    <QuestionCircleOutlined/>
                </Tooltip>
                </>
        }
    },
    "box22": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "align-items": "center",
            "border-width": "0 0 1px 0",
            "border-style": "dashed",
            "border-color": "#bec3ca",
            "margin": "0 0 6px 0"
        }
    },
    "icon11": {
        "kind": "Icon",
        "props": {
            "category": "io",
            "icon": IoIosCheckmark,
            "size": "32px",
            "color": "--indigo",
            "margin": "0px 8px 0px 0px"
        }
    },
    "text19": {
        "kind": "Text",
        "props":
            {
                "margin": "0px 0px 5px 0px",
                "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
                "color": "#4f5457",
                "children": "CDZV індикатори"
            }
    },
    "box23": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "align-items": "center",
            "border-width": "0 0 1px 0",
            "border-style": "dashed",
            "border-color": "#bec3ca",
            "margin": "0 0 6px 0"
        }
    },
    "icon12": {
        "kind": "Icon",
        "props": {
            "category": "io",
            "icon": IoIosCheckmark,
            "size": "32px",
            "color": "--indigo",
            "margin": "0px 8px 0px 0px"
        }
    },
    "text20": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "color": "#4f5457",
            "children": <>
                Бектестування
            </>
        }
    },
    "box24": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "align-items": "center",
            "margin": "0 0 6px 0",
            "border-width": "0 0 1px 0",
            "border-style": "dashed",
            "border-color": "#bec3ca"
        }
    },
    "icon13": {
        "kind": "Icon",
        "props": {
            "category": "io",
            "icon": IoIosCheckmark,
            "size": "32px",
            "color": "--indigo",
            "margin": "0px 8px 0px 0px"
        }
    },
    "text21": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "color": "#4f5457",
            "text-align": "left",
            "children": "Приклади стратегій"
        }
    },
    "box25": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "align-items": "center",
            "margin": "0 0 6px 0",
            "border-width": "0 0 1px 0",
            "border-style": "dashed",
            "border-color": "#bec3ca"
        }
    },
    "icon14": {
        "kind": "Icon",
        "props": {
            "category": "io",
            "icon": IoIosCheckmark,
            "size": "32px",
            "color": "--indigo",
            "margin": "0px 8px 0px 0px"
        }
    },
    "text22": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "color": "#4f5457",
            "children": "Можливості копіювання"
        }
    },
    "box26": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "align-items": "center",
            "margin": "0 0 6px 0",
            "border-width": "0 0 1px 0",
            "border-style": "dashed",
            "border-color": "#bec3ca"
        }
    },
    "icon15": {
        "kind": "Icon",
        "props":
            {
                "category": "io",
                "icon": IoIosCheckmark,
                "size": "32px",
                "color": "--indigo",
                "margin": "0px 8px 0px 0px"
            }
    },
    "text23": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "color": "#4f5457",
            "children": "Сервер Webhook"
        }
    },
    "box27": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "align-items": "center",
            "margin": "0 0 6px 0",
            "border-width": "0 0 1px 0",
            "border-style": "dashed",
            "border-color": "#bec3ca"
        }
    },
    "icon16": {
        "kind": "Icon",
        "props": {
            "category": "io",
            "icon": IoIosCheckmark,
            "size": "32px",
            "color": "--indigo",
            "margin": "0px 8px 0px 0px"
        }
    },
    "text24": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "color": "#4f5457",
            "children": "Спільнота трейдерів"
        }
    },
    "box28": {
        "kind": "Box",
        "props":
            {
                "min-width": "10px",
                "min-height": "10px",
                "display": "flex",
                "align-items": "center",
                "margin": "0 0 6px 0",
                "border-width": "0 0 1px 0",
                "border-style": "dashed",
                "border-color": "#bec3ca"
            }
    },
    "icon17": {
        "kind": "Icon",
        "props": {
            "category": "io",
            "icon": IoIosCheckmark,
            "size": "32px",
            "color": "--indigo",
            "margin": "0px 8px 0px 0px"
        }
    },
    "text25": {
        "kind": "Text",
        "props":
            {
                "margin": "0px 0px 5px 0px",
                "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
                "color": "#4f5457",
                "text-align": "left",
                "children": "Запити на модифікацію індикаторів"
            }
    },
    "box29": {
        "kind": "Box",
        "props": {
            "min-width": "10px",
            "min-height": "10px",
            "display": "flex",
            "align-items": "center",
            "margin": "0 0 6px 0",
            "border-width": "0 0 1px 0",
            "border-style": "dashed",
            "border-color": "#bec3ca"
        }
    },
    "icon18": {
        "kind": "Icon",
        "props": {
            "category": "io",
            "icon": IoIosCheckmark,
            "size": "32px",
            "color": "--indigo",
            "margin": "0px 8px 0px 0px"
        }
    },
    "text26": {
        "kind": "Text",
        "props": {
            "margin": "0px 0px 5px 0px",
            "font": "normal 400 18px/1.2 --fontFamily-sansHelvetica",
            "color": "#4f5457",
            "children": "Підтримка клієнтів"
        }
    },
    "box30": {
        "kind":
            "Box",
        "props":
            {
                "min-width":
                    "100px",
                "min-height":
                    "100px",
                "padding":
                    "35px 35px 50px 35px",
                "border-color":
                    "#26212d",
                "border-radius":
                    "15px",
                "box-shadow":
                    "0 4px 5px -1px rgba(0, 0, 0, 0.04)",
                "display":
                    "flex",
                "flex-direction":
                    "column",
                "background":
                    "--color-grey",
                "margin":
                    "45px 0 3 0",
                "border-width":
                    "1px",
                "border-style":
                    "solid",
                "position":
                    "relative",
                "z-index":
                    "1",
                "width":
                    "33%",
                "lg-width":
                    "100%",
                "lg-padding":
                    "40px 40px 40px 40px",
                "lg-align-items":
                    "stretch",
            }
    },
    "box31": {
        "kind":
            "Box",
        "props":
            {
                "min-width":
                    "100px",
                "min-height":
                    "100px",
                "sm-display":
                    "flex",
                "sm-flex-direction":
                    "column",
                "sm-align-items":
                    "center",
                "display":
                    "grid",
                "justify-content":
                    "center"
            }
    },
    "text27": {
        "kind":
            "Text",
        "props":
            {
                "margin":
                    "0px 0px 8px 0px",
                "font":
                    "normal 600 20px/1.5 Arial, sans-serif",
                "color":
                    "--light",
                "lg-margin":
                    "0px 0px 20px 0px",
                "children":
                    "ALL-IN-ONE+"
            }
    },
    "box32": {
        "kind":
            "Box",
        "props":
            {
                "min-width":
                    "10px",
                "min-height":
                    "10px",
                "display":
                    "flex",
                "align-items":
                    "center",
                "margin":
                    "0px 0px 8px 0px",
                "sm-flex-direction":
                    "column",
                "flex-wrap":
                    "wrap"
            }
    },
    "text28": {
        "kind":
            "Text",
        "props":
            {
                "margin":
                    "0px 0px 0px 0px",
                "font":
                    "--headline3",
                "color":
                    "#c1cbd1",
                "children":
                    <>
                        <Span
                            font="Arial, sans-serif"
                            overflow-wrap="normal"
                            word-break="normal"
                            white-space="normal"
                            text-indent="0"
                            text-overflow="clip"
                            hyphens="manual"
                            user-select="auto"
                            pointer-events="auto"
                            color="--darkL1"
                            margin="0px 0px 0px 0px"
                            // font-size="81"  // немного меньший размер
                        >
                            <Span
                                font="normal 400 14px/1.5 Arial, sans-serif"
                                color="--veles"
                                overflow-wrap="normal"
                                word-break="normal"
                                white-space="normal"
                                text-indent="0"
                                text-overflow="clip"
                                hyphens="manual"
                                user-select="auto"
                                pointer-events="auto"
                                display="block"
                                margin="5px 0px 0px 0px"
                            >
                                Щорічно
                            </Span>
                            <Span
                                font="normal 100 20px/1.5 Arial, sans-serif"
                                overflow-wrap="normal"
                                word-break="normal"
                                white-space="normal"
                                text-indent="0"
                                text-overflow="clip"
                                hyphens="manual"
                                user-select="auto"
                                pointer-events="auto"
                                // color="#808080"  // серый цвет для старой цены
                                margin="0px 0px 0px 0px"
                                text-decoration="line-through"  // перечеркивание
                                // font-size="21"  // немного меньший размер
                                display="block"
                                color="#1c1cbd1"
                            >
                                $1'800
                            </Span>
                            <Span
                                font="Arial, sans-serif"
                                color="yellow"  // зеленый цвет для экономии
                                font-size="18px"
                                margin="0px 0px 0px 0px"
                                font-weight="600"
                                display="block"
                                font="Arial, sans-serif"
                            >
                                {blackFridaySmall70UA()}
                                {/*We do have discounts, ask in the chat*/}
                                Економія $300
                            </Span>

                            <Span
                                font="normal 300 41px/1.5 Arial, sans-serif"
                                overflow-wrap="normal"
                                word-break="normal"
                                white-space="normal"
                                text-indent="0"
                                text-overflow="clip"
                                hyphens="manual"
                                user-select="auto"
                                pointer-events="auto"
                                // color="#808080"  // серый цвет для старой цены
                                margin="0px 0px -10px 0px"
                                // text-decoration="line-through"  // перечеркивание
                                // font-size="21"  // немного меньший размер
                                display="block"
                                color="--light"
                            >
                                $1'500
                            </Span>
                            <span style={{fontSize: 14, color: "#b21a2d", fontWeight: 600}}>
                                <Tooltip
                                    title={'Тільки для тих, хто платить кредитною карткою. В іншому випадку, ви можете підписатися на 15-денний пробний період за 30USDT.'}>
                                    15-денний пробний період <CreditCardOutlined/> <QuestionCircleOutlined/>
                                </Tooltip>
                            </span>
                        </Span>
                    </>
            }
    },
    "text29": {
        "kind":
            "Text",
        "props":
            {
                "margin":
                    "0px 0px 10px 0px",
                "font":
                    "normal 400 18px/1.5 --fontFamily-sans",
                "color":
                    "--white",
                "sm-text-align":
                    "center",
                "border-color":
                    "--color-green",
                "children":
                    <>
                        {" "}/{" "}
                        <Span
                            color="--veles"
                            overflow-wrap="normal"
                            word-break="normal"
                            white-space="normal"
                            text-indent="0"
                            text-overflow="clip"
                            hyphens="manual"
                            user-select="auto"
                            pointer-events="auto"
                        >
                            Щорічно
                        </Span>
                    </>
            }
    },
    "button2": {
        "kind":
            "Button",
        "props":
            {
                "background":
                    "--color-primary",
                "color":
                    "--light",
                "border-color":
                    "rgba(155, 108, 252, 0)",
                "border-radius":
                    "8px",
                "font":
                    "normal 400 17px/1.5 --fontFamily-sansHelvetica",
                "margin":
                    "0px 0px 25px 0px",
                "hover-background":
                    "--color-veles"
            }
    },
    "box33": {
        "kind":
            "Box",
        "props":
            {
                "min-width":
                    "10px",
                "min-height":
                    "10px",
                "display":
                    "flex",
                "align-items":
                    "center",
                "border-width":
                    "0 0 1px 0",
                "border-style":
                    "dashed",
                "border-color":
                    "#393341",
                "margin":
                    "0 0 6px 0"
            }
    },
    "icon19": {
        "kind":
            "Icon",
        "props":
            {
                "category":
                    "io",
                "icon":
                IoIosCheckmark,
                "size":
                    "32px",
                "color":
                    "--purple",
                "margin":
                    "0px 8px 0px 0px"
            }
    },
    "text30": {
        "kind":
            "Text",
        "props":
            {
                "margin":
                    "0px 0px 5px 0px",
                "font":
                    "normal 400 18px/1.2 --fontFamily-sansHelvetica",
                "color":
                    "#04ff3a",
                "children":
                    "Ви отримуєте 2 місяці безкоштовно"
            }
    },
    "box34": {
        "kind":
            "Box",
        "props":
            {
                "min-width":
                    "10px",
                "min-height":
                    "10px",
                "display":
                    "flex",
                "align-items":
                    "center",
                "border-width":
                    "0 0 1px 0",
                "border-style":
                    "dashed",
                "border-color":
                    "#393341",
                "margin":
                    "0 0 6px 0"
            }
    },
    "icon20": {
        "kind":
            "Icon",
        "props":
            {
                "category":
                    "io",
                "icon":
                IoIosCheckmark,
                "size":
                    "32px",
                "color":
                    "--purple",
                "margin":
                    "0px 8px 0px 0px"
            }
    },
    "text31": {
        "kind":
            "Text",
        "props":
            {
                "margin":
                    "0px 0px 5px 0px",
                "font":
                    "normal 400 18px/1.2 --fontFamily-sansHelvetica",
                "color":
                    "--light",
                "children":
                    <>
                        CDZV toolkit{" "}
                    </>
            }
    },
    "text31_0": {
        "kind":
            "Text",
        "props":
            {
                "margin":
                    "0px 0px 5px 0px",
                "font":
                    "normal 400 18px/1.2 --fontFamily-sansHelvetica",
                "color":
                    "--light",
                "children":
                    <>
                        Coin Rebalancer{" "}
                    </>
            }
    },
    "text31_1": {
        "kind":
            "Text",
        "props":
            {
                "margin":
                    "0px 0px 5px 0px",
                "font":
                    "normal 400 18px/1.2 --fontFamily-sansHelvetica",
                "color":
                    "--light",
                "display":
                    "flex",
                "align-items":
                    "center",
                "gap":
                    "8px",
                "children":
                    <>
                        SMART Grid PRO
                        {/*<Tooltip*/}
                        {/*    title="This module will be removed from subscriptions in the near future and will remain for current subscribers only. All subscribers will receive customized configurations for use.">*/}
                        {/*    <Badge*/}
                        {/*        count="? Limited offer"*/}
                        {/*        style={{*/}
                        {/*            fontSize: '10px',*/}
                        {/*            backgroundColor: '#f50',*/}
                        {/*            marginLeft: '0px',*/}
                        {/*            marginRight: '0px'*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</Tooltip>*/}
                    </>
            }
    },
    "box35": {
        "kind":
            "Box",
        "props":
            {
                "min-width":
                    "10px",
                "min-height":
                    "10px",
                "display":
                    "flex",
                "align-items":
                    "center",
                "border-width":
                    "0 0 1px 0",
                "border-style":
                    "dashed",
                "border-color":
                    "#393341",
                "margin":
                    "0 0 6px 0"
            }
    },
    "icon21": {
        "kind":
            "Icon",
        "props":
            {
                "category":
                    "io",
                "icon":
                IoIosCheckmark,
                "size":
                    "32px",
                "color":
                    "--purple",
                "margin":
                    "0px 8px 0px 0px"
            }
    },
    "text32": {
        "kind":
            "Text",
        "props":
            {
                "margin":
                    "0px 0px 5px 0px",
                "font":
                    "normal 400 18px/1.2 --fontFamily-sansHelvetica",
                "color":
                    "--light",
                "children":
                    "CDZV індикатори"
            }
    },
    "box36": {
        "kind":
            "Box",
        "props":
            {
                "min-width":
                    "100px",
                "min-height":
                    "100px"
            }
    },
    "box37": {
        "kind":
            "Box",
        "props":
            {
                "min-width":
                    "10px",
                "min-height":
                    "10px",
                "display":
                    "flex",
                "align-items":
                    "center",
                "border-width":
                    "0 0 1px 0",
                "border-style":
                    "dashed",
                "border-color":
                    "#393341",
                "margin":
                    "0 0 6px 0"
            }
    },
    "icon22": {
        "kind":
            "Icon",
        "props":
            {
                "category":
                    "io",
                "icon":
                IoIosCheckmark,
                "size":
                    "32px",
                "color":
                    "--purple",
                "margin":
                    "0px 8px 0px 0px"
            }
    },
    "text33": {
        "kind":
            "Text",
        "props":
            {
                "margin":
                    "0px 0px 5px 0px",
                "font":
                    "normal 400 18px/1.2 --fontFamily-sansHelvetica",
                "color":
                    "--light",
                "children":
                    <>
                        Бектестування
                    </>
            }
    },
    "box38": {
        "kind":
            "Box",
        "props":
            {
                "min-width":
                    "10px",
                "min-height":
                    "10px",
                "display":
                    "flex",
                "align-items":
                    "center",
                "margin":
                    "0 0 6px 0",
                "border-width":
                    "0 0 1px 0",
                "border-style":
                    "dashed",
                "border-color":
                    "#393341"
            }
    },
    "icon23": {
        "kind":
            "Icon",
        "props":
            {
                "category":
                    "io",
                "icon":
                IoIosCheckmark,
                "size":
                    "32px",
                "color":
                    "--purple",
                "margin":
                    "0px 8px 0px 0px"
            }
    },
    "text34": {
        "kind":
            "Text",
        "props":
            {
                "margin":
                    "0px 0px 5px 0px",
                "font":
                    "normal 400 18px/1.2 --fontFamily-sansHelvetica",
                "color":
                    "--light",
                "text-align":
                    "left",
                "children":
                    "Приклади стратегій"
            }
    },
    "box39": {
        "kind":
            "Box",
        "props":
            {
                "min-width":
                    "10px",
                "min-height":
                    "10px",
                "display":
                    "flex",
                "align-items":
                    "center",
                "margin":
                    "0 0 6px 0",
                "border-width":
                    "0 0 1px 0",
                "border-style":
                    "dashed",
                "border-color":
                    "#393341"
            }
    },
    "icon24": {
        "kind":
            "Icon",
        "props":
            {
                "category":
                    "io",
                "icon":
                IoIosCheckmark,
                "size":
                    "32px",
                "color":
                    "--purple",
                "margin":
                    "0px 8px 0px 0px"
            }
    },
    "text35": {
        "kind":
            "Text",
        "props":
            {
                "margin":
                    "0px 0px 5px 0px",
                "font":
                    "normal 400 18px/1.2 --fontFamily-sansHelvetica",
                "color":
                    "--light",
                "children":
                    "Можливості копіювання"
            }
    },
    "box40": {
        "kind":
            "Box",
        "props":
            {
                "min-width":
                    "10px",
                "min-height":
                    "10px",
                "display":
                    "flex",
                "align-items":
                    "center",
                "margin":
                    "0 0 6px 0",
                "border-width":
                    "0 0 1px 0",
                "border-style":
                    "dashed",
                "border-color":
                    "#393341"
            }
    },
    "icon25": {
        "kind":
            "Icon",
        "props":
            {
                "category":
                    "io",
                "icon":
                IoIosCheckmark,
                "size":
                    "32px",
                "color":
                    "--purple",
                "margin":
                    "0px 8px 0px 0px"
            }
    },
    "text36": {
        "kind":
            "Text",
        "props":
            {
                "margin":
                    "0px 0px 5px 0px",
                "font":
                    "normal 400 18px/1.2 --fontFamily-sansHelvetica",
                "color":
                    "--light",
                "children":
                    "Сервер Webhook"
            }
    },
    "box41": {
        "kind":
            "Box",
        "props":
            {
                "min-width":
                    "10px",
                "min-height":
                    "10px",
                "display":
                    "flex",
                "align-items":
                    "center",
                "margin":
                    "0 0 6px 0",
                "border-width":
                    "0 0 1px 0",
                "border-style":
                    "dashed",
                "border-color":
                    "#393341"
            }
    },
    "icon26": {
        "kind":
            "Icon",
        "props":
            {
                "category":
                    "io",
                "icon":
                IoIosCheckmark,
                "size":
                    "32px",
                "color":
                    "--purple",
                "margin":
                    "0px 8px 0px 0px"
            }
    },
    "text37": {
        "kind":
            "Text",
        "props":
            {
                "margin":
                    "0px 0px 5px 0px",
                "font":
                    "normal 400 18px/1.2 --fontFamily-sansHelvetica",
                "color":
                    "--light",
                "children":
                    "Спільнота трейдерів"
            }
    },
    "box42": {
        "kind":
            "Box",
        "props":
            {
                "min-width":
                    "10px",
                "min-height":
                    "10px",
                "display":
                    "flex",
                "align-items":
                    "center",
                "margin":
                    "0 0 6px 0",
                "border-width":
                    "0 0 1px 0",
                "border-style":
                    "dashed",
                "border-color":
                    "#393341"
            }
    },
    "icon27": {
        "kind":
            "Icon",
        "props":
            {
                "category":
                    "io",
                "icon":
                IoIosCheckmark,
                "size":
                    "32px",
                "color":
                    "--purple",
                "margin":
                    "0px 8px 0px 0px"
            }
    },
    "text38": {
        "kind":
            "Text",
        "props":
            {
                "margin":
                    "0px 0px 5px 0px",
                "font":
                    "normal 400 18px/1.2 --fontFamily-sansHelvetica",
                "color":
                    "--light",
                "text-align":
                    "left",
                "children":
                    "Запити на модифікацію індикаторів"
            }
    },
    "box43": {
        "kind":
            "Box",
        "props":
            {
                "min-width":
                    "10px",
                "min-height":
                    "10px",
                "display":
                    "flex",
                "align-items":
                    "center",
                "margin":
                    "0 0 6px 0",
                "border-width":
                    "0 0 1px 0",
                "border-style":
                    "dashed",
                "border-color":
                    "#393341"
            }
    },
    "icon28": {
        "kind":
            "Icon",
        "props":
            {
                "category":
                    "io",
                "icon":
                IoIosCheckmark,
                "size":
                    "32px",
                "color":
                    "--purple",
                "margin":
                    "0px 8px 0px 0px"
            }
    },
    "text39": {
        "kind":
            "Text",
        "props":
            {
                "margin":
                    "0px 0px 5px 0px",
                "font":
                    "normal 400 18px/1.2 --fontFamily-sansHelvetica",
                "color":
                    "--light",
                "children":
                    "Підтримка клієнтів"
            }
    },
    "box44": {
        "kind":
            "Box",
        "props":
            {
                "min-width":
                    "100px",
                "min-height":
                    "150px",
                "background":
                    "--color-white",
                "pointer-events":
                    "none",
                "width":
                    "100%",
                "lg-display":
                    "none"
            }
    }
};

const CdzvPricingUA = props => {
// const history = useHistory();


    const {getReferralCode} = useReferralCode();

    const {
        override,
        children,
        rest
    } = useOverrides(props, overrides, defaultProps);

    const [modal, contextHolder] = Modal.useModal();

    let [userLang, setUserLang] = useState('en');

    const inputEmailRef = useRef(null);
    const inputTVNameRef = useRef(null);

    const searchParams = useSearchParams()
    const pay = searchParams.get('pay')
    const pay_id = searchParams.get('pid')
    const utm_medium = searchParams.get('utm_medium')
    const utm_source = searchParams.get('utm_source')
    const utm_campaign = searchParams.get('utm_campaign')
    const utm_content = searchParams.get('utm_content')

// Remove chat if pay is successful
    useEffect(() => {
        if (pay === 'sxiniosax') {
            const element = document.getElementById('aichat');
            element.parentNode.removeChild(element);
            // let iframes = document.querySelectorAll('iframe');
            // for (let i = 0; i < iframes.length; i++) {
            //     iframes[i].parentNode.removeChild(iframes[i]);
            // }
        }
    });

    function getCurrentPrice(index) {
        return priceMonthly[index];
    }

    function redirectToMainPage() {
        window.location.href = 'https://panel.cdzv.com/';
    }

    async function postTelegramData(data = {}) {

        const url = 'https://kslukgrih5xid2ijqazpczomae0qkbbi.lambda-url.eu-central-1.on.aws/'

        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            // headers: {
            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json'
            // },
            redirect: "follow",
            body: JSON.stringify(data)
        });

        return await response.json();
    }

    async function postApiData(data = {}) {

        const url = 'https://api.cdzv.com/'

        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            redirect: "follow",
            body: JSON.stringify(data)
        });

        return await response.json();
    }

// get crypto invoice url
    async function getCryptoInvoiceUrl(amount, email, tv_user, paymentType, lang) {

        const api_key = 'W2K2YAA-73MMQTR-M94P8JB-939J3V9'

        // Generate order id
        const pid = Math.random().toString(36).substring(7);
        // POST
        const url = 'https://api.nowpayments.io/v1/invoice'
        const data = {
            "price_amount": amount,
            "price_currency": "usd",
            "order_id": pid,
            "order_description": "CDZV Toolkit",
            "ipn_callback_url": "https://cdzv.com/?pay=sxiniosax&pid=" + pid + "&tv_user=" + tv_user + "&utm_medium=" + email.replace('@', '_').replace('.', '-') + "&utm_source=" + tv_user.replace('@', '_').replace('.', '-') + "&utm_campaign=" + paymentType + "&utm_content=" + lang,
            "success_url": "https://cdzv.com/?pay=sxiniosax&pid=" + pid + "&tv_user=" + tv_user + "&utm_medium=" + email.replace('@', '_').replace('.', '-') + "&utm_source=" + tv_user.replace('@', '_').replace('.', '-') + "&utm_campaign=" + paymentType + "&utm_content=" + lang,
            "cancel_url": "https://cdzv.com/index#pricing"
        }

        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'x-api-key': api_key,
                'Content-Type': 'application/json'
            },
            redirect: "follow",
            body: JSON.stringify(data)
        });

        const json = await response.json();
        // alert(JSON.stringify(json));
        // ipn_callback_url
        return json.invoice_url;
    }

// crypto payment
    async function navigateToCryptoPayment(paymentType) {
        // check if email is entered
        // if not, show error message
        const email = inputEmailRef.current.input.value;
        let tvName = inputTVNameRef.current.input.value;
        const lang = userLang;
        const refCode = getReferralCode() || '';
        const amount = getCurrentPrice(paymentType).price;

        if (!tvName) {
            tvName = 'none';
        }

        // Validate email and TradingView profile name
        if (!email || email.length < 5 || !email.includes('@') || !email.includes('.')) {
            Modal.error({
                title: 'Помилка',
                content: 'Усі поля мають бути заповнені та мати правильні значення.',
                onOk() {
                    showPaymentsAddEmailDialog(paymentType);
                }
            });

            return;
        }

        const payment_url = await getCryptoInvoiceUrl(amount, email, tvName, getCurrentPrice(paymentType).package_id, lang);

        // create new user

        // send tracking data
        window.js_track(email);

        // send new user to Telegram
        postTelegramData({
            action: 'new_user_crypto',
            email: email,
            tv_user: tvName,
            type: getCurrentPrice(paymentType).package_id,
            package: getCurrentPrice(paymentType).name,
            lang: lang,
            ref_code: refCode
        }).then(data => {
        }).finally(() => {
            // send new user to Telegram
            postApiData({
                action: 'new_user',
                email: email,
                tv_user: tvName,
                type: getCurrentPrice(paymentType).package_id,
                package: getCurrentPrice(paymentType).name,
                lang: lang,
                ref_code: refCode
            }).then(data => {
            }).finally(() => {
                // redirect to payment page
                window.location.href = payment_url;
            });
        });
    }

// Redirect to payment page
    function navigateToPayment(fiat, paymentType) {
        // check if email is entered
        // if not, show error message
        const email = inputEmailRef.current.input.value;
        let tvName = inputTVNameRef.current.input.value;
        const lang = userLang;
        const refCode = getReferralCode() || '';

        if (!tvName) {
            tvName = 'none';
        }

        // Validate email and TradingView profile name
        if (!email || email.length < 5 || !email.includes('@') || !email.includes('.')) {
            Modal.error({
                title: 'Помилка',
                content: 'Усі поля мають бути заповнені та мати правильні значення.',
                onOk() {
                    showPaymentsAddEmailDialog(paymentType);
                }
            });

            return;
        }

        if (paymentType === 0) {
            try {
                // send new user to API
                postApiData({
                    action: 'payment',
                    email: email,
                    tv_user: tvName,
                    package: -2,
                    payid: 'free_' + Math.random().toString(36).substring(1),
                    lang: lang,
                    ref_code: refCode
                }).then(data => {
                });
            } catch (e) {
                console.log(e)
            }
        }

        // create new user

        // send tracking data
        window.js_track(email);

        // send new user to Telegram
        postTelegramData({
            action: 'new_user',
            email: email,
            tv_user: tvName,
            type: getCurrentPrice(paymentType).package_id,
            package: getCurrentPrice(paymentType).name,
            lang: lang,
            ref_code: refCode
        }).then(data => {
        }).finally(() => {
            // send new user to Telegram
            postApiData({
                action: 'new_user',
                email: email,
                tv_user: tvName,
                type: getCurrentPrice(paymentType).package_id,
                package: getCurrentPrice(paymentType).name,
                lang: lang,
                ref_code: refCode
            }).then(data => {
            }).finally(() => {
                // redirect to payment page

                if (paymentType === 0) {
                    window.location = 'https://panel.cdzv.com/login?email=' + email;
                    return;
                }

                if (fiat === true) {
                    window.location.href = getCurrentPrice(paymentType).url + '?email=' + email + '&prefilled_email=' + email + '&utm_medium=' + email.replace('@', '_').replace('.', '-') + '&utm_source=' + tvName.replace('@', '_').replace('.', '-') + '&utm_campaign=' + getCurrentPrice(paymentType).package_id + '&utm_content=' + lang;
                } else {
                    window.location.href = getCurrentPrice(paymentType).crypto_url + '&email=' + email + '&tv_user=' + tvName + '&utm_medium=' + email.replace('@', '_').replace('.', '-') + '&utm_source=' + tvName.replace('@', '_').replace('.', '-') + '&utm_campaign=' + getCurrentPrice(paymentType).package_id + '&utm_content=' + lang;
                }
            });
        });
    }

    function showPaymentsAddEmailDialog(paymentType) {

        const instance = modal.confirm({
            title: "Введіть свій e-mail та ім'я профілю TradingView",
            height: 600,
            width: 700,
            content: (
                <Space
                    direction="vertical"
                    size="middle"
                    style={{
                        marginTop: '20px',
                        display: 'flex',
                    }}>
                    {/*<p>*/}
                    {/*    Fill in all fields, and click <strong>Ok</strong> to proceed to the page.*/}
                    {/*</p>*/}
                    <Input
                        ref={inputEmailRef}
                        placeholder="Введіть Ваш e-mail"
                        style={{width: '100%'}}
                    />
                    <Input
                        ref={inputTVNameRef}
                        placeholder="Введіть назву Вашого профілю TradingView"
                        style={{width: '100%'}}
                    />
                    <Select
                        // ref={inputLangRef}
                        defaultValue="ua"
                        placeholder="Виберіть мову"
                        style={{width: '100%'}}
                        options={
                            [
                                {
                                    value: 'en', label: 'English'
                                },
                                {
                                    value: 'ua', label: 'Українська'
                                },
                                {
                                    value: 'ru', label: 'Русский'
                                }]
                        }
                        onChange={(value) => {
                            userLang = value;
                        }}
                    />
                    <p>
                        <InfoCircleOutlined style={{color: 'red'}}/> Якщо у Вас немає облікового запису TradingView,
                        напишіть '<strong>none</strong>'.<br/>
                        <InfoCircleOutlined style={{color: 'red'}}/> Якщо у Вас виникли проблеми з відправкою платежу,
                        Вам необхідно написати нам за адресою <strong><a
                        href="mailto:support@cdzv.com">support@cdzv.com</a></strong>.
                    </p>
                </Space>
            ),
            footer: (_, {
                OkBtn, CancelBtn
            }) => (
                <>
                    {paymentType === 0 ? <><CancelBtn/> <Button
                        onClick={() => {
                            navigateToPayment(false, paymentType);
                            instance.destroy();
                        }}>Зареєструйте обліковий запис</Button></> : <><CancelBtn/> <Button
                        onClick={async () => {
                            await navigateToCryptoPayment(paymentType);
                            instance.destroy();
                        }}><GlobalOutlined/> Оплата криптовалютою</Button> <Button
                        onClick={() => {
                            navigateToPayment(true, paymentType);
                            instance.destroy();
                        }}
                    ><CreditCardOutlined/> Оплата карткою</Button>
                    </>
                    }
                </>
            ),
            // onOk() {
            //     navigateToPayment(true, paymentType);
            // }
        });

        instance.update({
            okText: 'Ok',
        });
    }

    const showConfirmPayment = () => {
        // redirectToMainPage();

        Modal.info({
            title: 'Congratulations\n',
            width: 700,
            content: (
                <h4>
                    E-Mail: {utm_medium.replace('_', '@').replace('-', '.')}<br/>
                    TradingView: {utm_source.replace('_', '@').replace('-', '.')}<br/><br/>
                    Ви успішно оплатили підписку на використання CDZV сервісів.<br/><br/>
                    Нам потрібен час, щоб обробити Ваш платіж. Це буде швидко.<br/><br/>
                    Щойно оплату буде опрацьовано, ми надішлемо вам листа з інструкціями щодо встановлення індикаторів у
                    TradingView.
                </h4>
            ),
            footer: (_, {
                OkBtn
            }) => (
                <>
                    <h3>Зачекайте кілька секунд...</h3>
                </>
                // <OkBtn/>
            ),
            // onOk: (e) => {
            //     redirectToMainPage();
            // }
        });

        // send new user to Telegram
        try {
            postTelegramData({
                action: 'payment',
                email: utm_medium.replace('_', '@').replace('-', '.'),
                tv_user: utm_source.replace('_', '@').replace('-', '.'),
                package: utm_campaign,
            }).then(data => {
            });
        } catch (e) {
            console.log(e)
        }

        try {
            // send new user to API
            postApiData({
                action: 'payment',
                email: utm_medium.replace('_', '@').replace('-', '.'),
                tv_user: utm_source.replace('_', '@').replace('-', '.'),
                package: utm_campaign,
                payid: pay_id,
                lang: utm_content
            }).then(data => {
            });
        } catch (e) {
            console.log(e)
        }

        setTimeout(() => {
            redirectToMainPage();
        }, 20000);
    }
    return <Section {...rest}>
        {pay && showConfirmPayment()}
        {contextHolder}
        <Override slot="SectionContent" background="--color-white"/>
        <Box {...override("box")}>
            <Text {...override("text")} />
            <Text {...override("text1")} />
            <Box {...override("box1")}>
                <Box {...override("box2")}>
                    <Box {...override("box3")}>
                        <Box {...override("box4")}>
                            <Text {...override("text2")} />
                            <Box {...override("box5")}>
                                <Text {...override("text3")} />
                                <Text {...override("text4")} />
                            </Box>
                            <Button {...override("button")}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        showPaymentsAddEmailDialog(0);
                                    }}
                            >
                                Спробувати безкоштовно
                            </Button>
                        </Box>
                        <Box {...override("box6")}>
                            <Box {...override("box7")}>
                                <Icon {...override("icon")} />
                                <Text {...override("text5")} />
                            </Box>
                            <Box {...override("box7")}>
                                <Icon {...override("icon")} />
                                <Text {...override("text5_0")} />
                            </Box>
                            <Box {...override("box7")}>
                                <Icon {...override("icon")} />
                                <Text {...override("text5_1")} />
                            </Box>
                            {/*<Box {...override("box8")}>*/}
                            {/*    <Icon {...override("icon1")} />*/}
                            {/*    <Text {...override("text6")} />*/}
                            {/*</Box>*/}
                            <Box {...override("box9")}>
                                <Icon {...override("icon2")} />
                                <Text {...override("text7")} />
                            </Box>
                            <Box {...override("box10")}>
                                <Icon {...override("icon3")} />
                                <Text {...override("text8")} />
                            </Box>
                            <Box {...override("box11")}>
                                <Icon {...override("icon4")} />
                                <Text {...override("text9")} />
                            </Box>
                            {/*<Box {...override("box12")}>*/}
                            {/*    <Icon {...override("icon5")} />*/}
                            {/*    <Text {...override("text10")} />*/}
                            {/*</Box>*/}
                            <Box {...override("box13")}>
                                <Icon {...override("icon6")} />
                                <Text {...override("text11")} />
                            </Box>
                            <Box {...override("box14")}>
                                <Icon {...override("icon7")} />
                                <Text {...override("text12")} />
                            </Box>
                            <Box {...override("box15")}>
                                <Icon {...override("icon8")} />
                                <Text {...override("text13")} />
                            </Box>
                            <Box {...override("box16")}>
                                <Icon {...override("icon9")} />
                                <Text {...override("text14")} />
                            </Box>
                        </Box>
                    </Box>
                    <Box {...override("box17")}>
                        <Text {...override("text15")} />
                        <Box {...override("box18")}>
                            <Box {...override("box19")}>
                                <Text {...override("text16")} />
                                {/*<Text {...override("text17")} />*/}
                            </Box>
                            <Button {...override("button1")}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        showPaymentsAddEmailDialog(1);
                                    }}
                            >
                                Оформити підписку
                            </Button>
                        </Box>
                        <Box {...override("box20")}>
                            <Box {...override("box21")}>
                                <Icon {...override("icon10")} />
                                <Text {...override("text18")} />
                            </Box>
                            <Box {...override("box22")}>
                                <Icon {...override("icon11")} />
                                <Text {...override("text19")} />
                            </Box>
                            <Box {...override("box21")}>
                                <Icon {...override("icon10")} />
                                <Text {...override("text18_0")} />
                            </Box>
                            <Box {...override("box21")}>
                                <Icon {...override("icon10")} />
                                <Text {...override("text18_1")} />
                            </Box>
                            <Box {...override("box23")}>
                                <Icon {...override("icon12")} />
                                <Text {...override("text20")} />
                            </Box>
                            <Box {...override("box24")}>
                                <Icon {...override("icon13")} />
                                <Text {...override("text21")} />
                            </Box>
                            <Box {...override("box25")}>
                                <Icon {...override("icon14")} />
                                <Text {...override("text22")} />
                            </Box>
                            <Box {...override("box26")}>
                                <Icon {...override("icon15")} />
                                <Text {...override("text23")} />
                            </Box>
                            <Box {...override("box27")}>
                                <Icon {...override("icon16")} />
                                <Text {...override("text24")} />
                            </Box>
                            <Box {...override("box28")}>
                                <Icon {...override("icon17")} />
                                <Text {...override("text25")} />
                            </Box>
                            <Box {...override("box29")}>
                                <Icon {...override("icon18")} />
                                <Text {...override("text26")} />
                            </Box>
                        </Box>
                    </Box>
                    <Box {...override("box30")}>
                        <Box {...override("box31")}>
                            <Text {...override("text27")} />
                            <Box {...override("box32")}>
                                <Text {...override("text28")} />
                                {/*<Text {...override("text29")} />*/}
                            </Box>
                        </Box>
                        <Button {...override("button2")}
                                onClick={(event) => {
                                    event.preventDefault();
                                    showPaymentsAddEmailDialog(2);
                                }}
                        >
                            Оформити підписку
                        </Button>
                        {/*<Box {...override("box33")}>*/}
                        {/*    <Icon {...override("icon19")} />*/}
                        {/*    <Text {...override("text30")} />*/}
                        {/*</Box>*/}
                        <Box {...override("box34")}>
                            <Icon {...override("icon20")} />
                            <Text {...override("text31")} />
                        </Box>
                        <Box {...override("box35")}>
                            <Icon {...override("icon21")} />
                            <Text {...override("text32")} />
                        </Box>
                        <Box {...override("box34")}>
                            <Icon {...override("icon20")} />
                            <Text {...override("text31_0")} />
                        </Box>
                        <Box {...override("box34")}>
                            <Icon {...override("icon20")} />
                            <Text {...override("text31_1")} />
                        </Box>
                        {/*<Box {...override("box36")}>*/}
                        <Box {...override("box37")}>
                            <Icon {...override("icon22")} />
                            <Text {...override("text33")} />
                        </Box>
                        <Box {...override("box38")}>
                            <Icon {...override("icon23")} />
                            <Text {...override("text34")} />
                        </Box>
                        <Box {...override("box39")}>
                            <Icon {...override("icon24")} />
                            <Text {...override("text35")} />
                        </Box>
                        {/*</Box>*/}
                        <Box {...override("box40")}>
                            <Icon {...override("icon25")} />
                            <Text {...override("text36")} />
                        </Box>
                        <Box {...override("box41")}>
                            <Icon {...override("icon26")} />
                            <Text {...override("text37")} />
                        </Box>
                        <Box {...override("box42")}>
                            <Icon {...override("icon27")} />
                            <Text {...override("text38")} />
                        </Box>
                        <Box {...override("box43")}>
                            <Icon {...override("icon28")} />
                            <Text {...override("text39")} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
        <Box {...override("box44")} />
        {children}
    </Section>;
};

Object.assign(CdzvPricingUA, {
    ...
        Section,
    defaultProps,
    overrides
});
export default CdzvPricingUA;