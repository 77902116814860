import React from "react";
import theme from "theme";
import {Theme, Image, Box, Text, Button, Section} from "@quarkly/widgets";
import {Helmet} from "react-helmet";
import {GlobalQuarklyPageStyles} from "global-page-styles";
import {RawHtml, Override} from "@quarkly/components";
import * as Components from "components";
import ComparisonTableRU from "../components/ComparisonTable_ru";
import {CdzvFaqRU, CdzvRebalnacerRU} from "components";
// import {useMediaQuery} from 'react-responsive';

const blackFriday = () => {
    return null;
    // return <Image
    //     style={{
    //         padding: '50px 0px 0px 0px',
    //     }}
    //     src="https://cdzv.com/strategy_img/Image_2024-11-25_22-38-13.jpg"
    //     width={"100%"}
    //     preview={false}
    // />
}

export default (() => {

    // const isMobile = useMediaQuery({maxWidth: 450});
    // const isMobileHR = useMediaQuery({maxWidth: 767});
    // const isTablet = useMediaQuery({minWidth: 768, maxWidth: 819});
    // const isTabletHR = useMediaQuery({minWidth: 820, maxWidth: 1280});
    // const isDesktop = useMediaQuery({minWidth: 1281});

    return <Theme theme={theme}>
        <GlobalQuarklyPageStyles pageUrl={"index"}/>
        <Helmet>
            <title>CDZV | Создавайте, тестируйте и автоматизируйте торговые стратегии без программирования.</title>
            <meta name="keywords"
                  content="Code Zero Visual Trading, CDZV, TradingView, индикатор, стратегия, бэктестинг, альго-трейдинг, трейдинг, форекс, акции, криптовалюта, прибыль, алгоритмическая торговля, автоматизированная торговля, торговые инструменты, торговая платформа, торговля без кода, визуальная торговля, автоматизация торговли, создание стратегии, построение стратегии, тестирование стратегии, автоматизация стратегии, вебхуки"/>
            <meta name="description"
                  content="Code Zero Visual Trading (CDZV) для TradingVieew. Создавайте, тестируйте и автоматизируйте свою торговую стратегию за считанные минуты без программирования. Быстро оценивайте свои идеи и превращайте их в прибыльные решения с помощью наших инструментов. Создавайте стратегии за считанные минуты без навыков программирования."/>
            <meta property="og:description"
                  content="Code Zero Visual Trading (CDZV) для TradingVieew. Создавайте, тестируйте и автоматизируйте свою торговую стратегию за считанные минуты без программирования. Быстро оценивайте свои идеи и превращайте их в прибыльные решения с помощью наших инструментов. Создавайте стратегии за считанные минуты без навыков программирования."/>
            <meta name="author" content="Code Zero Visual Trading"/>
            <link rel="canonical" href="https://cdzv.com/"/>
            <link rel="shortcut icon" href="favicon.ico"/>
        </Helmet>
        <Components.CdzvMenuRU/>
        {blackFriday()}
        <Section padding="140px 0 40px 0" quarkly-title="CDZVHero" background="--color-white" id="home">
            <Override slot="SectionContent" display="flex" width="1280px"/>
            <Box
                min-width="100px"
                min-height="100px"
                display="flex"
                justify-content="space-around"
                lg-flex-direction="column"
                lg-overflow-y="hidden"
                lg-overflow-x="hidden"
                flex="0 1 auto"
            >
                <Box
                    min-width="100px"
                    min-height="100px"
                    width="50%"
                    lg-width="100%"
                    lg-padding="0 50px 50px 50px"
                    padding="0px 80px 0px 80px"
                    sm-padding="0 15px 50px 15px"
                >
                    <Box
                        min-width="10px"
                        min-height="10px"
                        display="none"
                        margin="10px 0px 0px 0px"
                        border-radius="25px"
                        border-color="--color-lightD2"
                        sm-flex-direction="column"
                        align-items="center"
                        sm-align-items="flex-start"
                        justify-content="flex-start"
                    >
                        <Image
                            src="https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/tv_logo_white_horizontal.png?v=2024-06-03T15:58:42.784Z"
                            display="block"
                            width="200px"
                            align-self="auto"
                            margin="0px 0px 10px 0px"
                            srcSet="https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/tv_logo_white_horizontal.png?v=2024-06-03T15%3A58%3A42.784Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/tv_logo_white_horizontal.png?v=2024-06-03T15%3A58%3A42.784Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/tv_logo_white_horizontal.png?v=2024-06-03T15%3A58%3A42.784Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/tv_logo_white_horizontal.png?v=2024-06-03T15%3A58%3A42.784Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/tv_logo_white_horizontal.png?v=2024-06-03T15%3A58%3A42.784Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/tv_logo_white_horizontal.png?v=2024-06-03T15%3A58%3A42.784Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/tv_logo_white_horizontal.png?v=2024-06-03T15%3A58%3A42.784Z&quality=85&w=3200 3200w"
                            sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                        />
                    </Box>
                    {/*<Text*/}
                    {/*    margin="0px 0px 20px 0px"*/}
                    {/*    font="normal 800 50px/1.2 --fontFamily-sans"*/}
                    {/*    sm-font="normal 700 42px/1.2 --fontFamily-sans"*/}
                    {/*    color="--darkL2"*/}
                    {/*    display="none"*/}
                    {/*>*/}
                    {/*    Code Zero*/}
                    {/*    <br/>*/}
                    {/*    Strategy Builder*/}
                    {/*</Text>*/}
                    <Components.QuarklycommunityKitLoopText slides="автоматизатор, бектестер, конструктор"
                                                            font="normal 800 40px/1.2 --fontFamily-sans"
                                                            padding="10px 0px 0px 0px">
                        <Override slot="After Text" margin="0 0px 0 0px">
                            {" "}{" "}{" "}{" "}
                        </Override>
                        <Override slot="Before Text" margin="0 0px 0 0px">
                            CDZV Toolkit <span role="img" aria-label="emoji">📈</span> <span style={{
                            fontSize: '0.5em',
                        }}>для TradingView</span>
                        </Override>
                        <Override slot="Looped Text" margin="1px 0px 16px 0px"/>
                    </Components.QuarklycommunityKitLoopText>
                    <Text
                        margin="10px 0px 50px 0px"
                        font="normal 300 24px/1.2 --fontFamily-sans"
                        color="rgba(25, 30, 34, 0.73)"
                        lg-margin="0px 0px 30px 0px"
                        text-align="justify"
                    >
                        Создавайте идеальные торговые стратегии без программирования! Наш бэктестер позволяет легко
                        создавать стратегии с помощью визуального конструктора, тестировать их на исторических данных и
                        настраивать автоматические сигналы для отправки на биржи, в Telegram или другие платформы. Этому
                        легко научиться - мы предоставим все необходимые инструкции и поддержку.
                        {/*Trade smarter, not harder.*/}
                        {/*Choose from 190+ proven strategies or build your own - no coding needed. Test your strategy, get*/}
                        {/*alerts for the best trading moments, and let automation do the heavy lifting. One simple*/}
                        {/*platform for smarter trading.*/}
                        {/*Discover over 190 ready-to-run trading strategies and a powerful, intuitive backtester. Use our*/}
                        {/*proven strategies or create your own with CDZV Toolkit for TradingView - no programming*/}
                        {/*knowledge required. Test and automate your trading in minutes. One pricing plan includes all*/}
                        {/*features, no hidden fees.*/}
                        {/*Build, test and automate trading strategies in minutes. Use CDZV Toolkit for TradingView or our ready-made strategies without programming knowledge.*/}
                    </Text>
                    <Box
                        min-width="10px"
                        min-height="10px"
                        display="flex"
                        sm-flex-direction="column"
                        sm-align-items="stretch"
                        lg-justify-content="center"
                    >
                        <Button
                            margin="0px 15px 0px 0px"
                            padding="12px 28px 12px 28px"
                            background="#631876"
                            border-radius="8px"
                            font="normal 400 17px/1.5 --fontFamily-sans"
                            sm-margin="0px 0 15px 0px"
                            transition="background-color 0.2s ease 0s"
                            border-width="2px"
                            border-style="solid"
                            border-color="rgba(63, 36, 216, 0)"
                            hover-color="--darkL1"
                            hover-background="--color-indigo"
                            type="link"
                            href="#pricing"
                            disabled={false}
                            text-decoration-line="initial"
                            text-align="center"
                        >
                            Начать
                        </Button>
                        <Image
                            src="https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/emojies.webp?v=2024-06-03T18:15:49.125Z"
                            display="none"
                            width="100px"
                            sm-align-self="center"
                            srcSet="https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/emojies.webp?v=2024-06-03T18%3A15%3A49.125Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/emojies.webp?v=2024-06-03T18%3A15%3A49.125Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/emojies.webp?v=2024-06-03T18%3A15%3A49.125Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/emojies.webp?v=2024-06-03T18%3A15%3A49.125Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/emojies.webp?v=2024-06-03T18%3A15%3A49.125Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/emojies.webp?v=2024-06-03T18%3A15%3A49.125Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/emojies.webp?v=2024-06-03T18%3A15%3A49.125Z&quality=85&w=3200 3200w"
                            sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                        />
                        <Text
                            margin="0px 0px 0px 0px"
                            align-self="center"
                            padding="0px 0px 0px 10px"
                            text-align="center"
                            font="--base"
                            sm-align-self="center"
                            sm-order="0"
                            sm-flex="0 1 auto"
                            sm-text-align="center"
                            display="none"
                        >
                            4637 пользователей
                        </Text>
                    </Box>
                </Box>
                <Image
                    src="https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/head_mac.png?v=2024-06-03T14:18:33.517Z"
                    display="block"
                    width="50%"
                    object-fit="cover"
                    lg-width="100%"
                    object-position="0 0"
                    padding="15px 0px 15px 15px"
                    border-radius="25px 0 0 25px"
                    background="#ffffff"
                    sm-margin="0px 0px 0px 0px"
                    lg-margin="0px 0px 0px 0"
                    lg-text-align="left"
                    lg-padding="15px 0px 15px 0px"
                    sm-padding="15px 0 15px 0"
                    srcSet="https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/head_mac.png?v=2024-06-03T14%3A18%3A33.517Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/head_mac.png?v=2024-06-03T14%3A18%3A33.517Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/head_mac.png?v=2024-06-03T14%3A18%3A33.517Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/head_mac.png?v=2024-06-03T14%3A18%3A33.517Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/head_mac.png?v=2024-06-03T14%3A18%3A33.517Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/head_mac.png?v=2024-06-03T14%3A18%3A33.517Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665dd05e1d712a0023346c54/images/head_mac.png?v=2024-06-03T14%3A18%3A33.517Z&quality=85&w=3200 3200w"
                    sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
                />
            </Box>
            {/*<div*/}
            {/*    style={{*/}
            {/*        display: 'flex',*/}
            {/*        justifyContent: 'center',*/}
            {/*        alignItems: 'center',*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Alert*/}
            {/*        style={{*/}
            {/*            marginTop: '30px',*/}
            {/*            width: '50%',*/}
            {/*        }}*/}
            {/*        // message="Make More Money Trading! 💰"*/}
            {/*        description={<>*/}
            {/*            <div style={{*/}
            {/*                fontSize: '14px',*/}
            {/*                textAlign: 'center',*/}
            {/*            }}>*/}
            {/*                Need to validate your trading strategy but short on time? We've got you covered! 💪<br/>*/}
            {/*                Our pros will test your trading strategy on historical data using CDZV Toolkit. Don't waste*/}
            {/*                your time - let us handle it!<br/><br/>*/}
            {/*                Price: $90/hour. Usually takes 2-6 hours (depends on the complexity).<br/><br/>*/}
            {/*                <Button*/}
            {/*                    type="primary"*/}
            {/*                    size="large"*/}
            {/*                    style={{*/}

            {/*                        width: '310px',*/}
            {/*                        // background: '#631876',*/}
            {/*                        borderRadius: '8px',*/}
            {/*                    }}*/}
            {/*                    onClick={() => {*/}
            {/*                        window.open('mailto:support@cdzv.com', '_blank');*/}
            {/*                    }}*/}
            {/*                    icon={<MessageOutlined/>}*/}
            {/*                >*/}
            {/*                    Message our support team now! 🚀*/}
            {/*                </Button>*/}
            {/*            </div>*/}
            {/*        </>}*/}
            {/*        type="info"/>*/}
            {/*</div>*/}
        </Section>
        <Components.CdzvRebalnacerRU/>
        <Components.CdzvEchanges
            sm-display="flex"
            sm-overflow-y="visible"
            sm-text-align="center"
            margin="0px 0px 0px 0px"
            padding="0px 0 30px 0"
        >
            <Override
                slot="box"
                sm-flex="0 1 auto"
                sm-flex-direction="column"
                sm-align-items="center"
                sm-justify-content="center"
                sm-align-content="center"
                md-display="flex"
                md-align-items="center"
                md-justify-content="center"
                md-flex-wrap="wrap"
                md-align-content="stretch"
                md-flex-direction="column"
                sm-width="90%"
                sm-height="auto"
            />
            <Override slot="image2" md-padding="0px 0px 20px 0px" sm-height="100px" padding="0px 0px 3px 0px"/>
            <Override
                slot="image3"
                md-padding="0px 0px 10px 0px"
                sm-height="80px"
                src="https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/full-kucoin-logo-600x139.webp?v=2024-06-04T17:09:28.076Z"
                margin="4px 0px 0px 0px"
            />
            <Override slot="box4" md-padding="0px 0px 20px 0px" sm-width="100%" width="13%"/>
            <Override slot="box5" md-padding="0px 0px 20px 0px" sm-width="100%"/>
            <Override slot="box6" md-padding="0px 0px 20px 0px" sm-width="100%"/>
            <Override slot="box7" md-width="20%" sm-width="30%"/>
            <Override slot="image4" sm-height="45px" padding="3px 0px 0px 0px"/>
            <Override slot="image1" sm-height="70px"/>
            <Override slot="box1" sm-width="100%"/>
            <Override slot="image" sm-height="70px"/>
            <Override slot="image5" sm-height="90px" padding="0px 0px 5px 0px"/>
            <Override slot="image6"/>
            <Override slot="box2" sm-width="100%"/>
            <Override slot="box3" sm-width="100%" width="11%"/>
        </Components.CdzvEchanges>
        <Components.CdzvSmartGridRU/>

        <Components.CdzvStatsRU padding="10px 0 10px 0" display="none"/>
        <Components.CdzvStrategyPackRU>
            <Override slot="box2" lg-margin="0px 0px 0px 30px" sm-margin="0px 0px 0px 0px"/>
        </Components.CdzvStrategyPackRU>
        {/*<Components.CdzvProfits/>*/
        }
        {/*<Components.CdzvCopyTrades/>*/
        }
        {/*<Components.CdzvFeatures background="--color-white">*/
        }
        {/*    <Override slot="text4">*/
        }
        {/*        Analyze the results of trades on different time frames and change settings to improve statistics. You*/
        }
        {/*        can use a combination of several indicators to find rare entry points.*/
        }
        {/*    </Override>*/
        }
        {/*    <Override slot="text7">*/
        }
        {/*        After successful testing, automate the sending of trading signals to the exchange or messenger.*/
        }
        {/*    </Override>*/
        }
        {/*</Components.CdzvFeatures>*/
        }
        <Components.CdzvScamRU/>
        <Components.CdzvBenefitsRU background="--color-light" padding="0px 0 0px 0">
            <Override slot="box1" background="--color-light"/>
            <Override slot="image" background="--color-light" border-radius="50px"/>
        </Components.CdzvBenefitsRU>
        {/*<Components.QuarklycommunityKitYouTube url="https://www.youtube.com/watch?v=CcT_ziAkdpc" modestbranding*/
        }
        {/*                                       quarkly-title="Youtube1"/>*/
        }
        <Components.CdzvToolkitRU/>
        <ComparisonTableRU/>
        {/*<div*/}
        {/*    style={{*/}
        {/*        display: 'flex',*/}
        {/*        justifyContent: 'center',*/}
        {/*        alignItems: 'center',*/}
        {/*        padding: '0px 0',*/}
        {/*        width: '100%',*/}
        {/*        // background: 'linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/CDZV%20Members%20Area%202024-06-15%2017-52-08.png?v=2024-06-15T15:52:31.116Z) center/cover',*/}
        {/*        // color: '--light',*/}
        {/*        // font: '--base',*/}
        {/*        // mixBlendMode: 'luminosity'*/}
        {/*    }}>*/}
        {/*    <div*/}
        {/*        style={{*/}
        {/*            display: 'flex',*/}
        {/*            justifyContent: 'center',*/}
        {/*            alignItems: 'center',*/}
        {/*            padding: '0px 0',*/}
        {/*            width: (isMobile || isMobileHR) ? '100%' : '80%',*/}
        {/*            // background: 'linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/CDZV%20Members%20Area%202024-06-15%2017-52-08.png?v=2024-06-15T15:52:31.116Z) center/cover',*/}
        {/*            // color: '--light',*/}
        {/*            // font: '--base',*/}
        {/*            // mixBlendMode: 'luminosity'*/}
        {/*        }}*/}
        {/*    >*/}
        {/*        <Card*/}
        {/*            bordered={false}*/}
        {/*            style={{*/}
        {/*                width: '80%',*/}
        {/*            }}*/}
        {/*            title={<>*/}
        {/*                <span style={{*/}
        {/*                    display: 'flex',*/}
        {/*                    flexWrap: 'wrap',*/}
        {/*                    flexDirection: 'row',*/}
        {/*                    alignItems: 'flex-end'*/}
        {/*                }}>*/}
        {/*                        <span style={{*/}
        {/*                            fontSize: isMobile ? '2em' : '2.5em',*/}
        {/*                        }}>Ask any questions</span> <span*/}
        {/*                    style={{*/}
        {/*                        fontSize: isMobile ? '0.8em' : '1.1em',*/}
        {/*                        color: '#631876',*/}
        {/*                    }}>we speak English, Ukrainian and Russian*/}
        {/*                            </span>*/}
        {/*                </span>*/}
        {/*            </>*/}
        {/*            }*/}
        {/*            style={{*/}
        {/*                whitSpace: 'normal',*/}
        {/*                height: 'auto'*/}
        {/*            }}*/}
        {/*            actions={[*/}
        {/*                <Space wrap={true}*/}
        {/*                       style={{*/}
        {/*                           display: 'flex',*/}
        {/*                           justifyContent: 'center',*/}
        {/*                       }}*/}
        {/*                >*/}
        {/*                    <Button*/}
        {/*                        type="primary"*/}
        {/*                        size="large"*/}
        {/*                        style={{*/}
        {/*                            // marginRight: '10px',*/}
        {/*                            width: '250px',*/}
        {/*                            background: '#631876',*/}
        {/*                            borderColor: '#0088cc',*/}
        {/*                            borderRadius: '8px',*/}
        {/*                            fontWeight: '500',*/}
        {/*                            // color: '#fff'*/}
        {/*                        }}*/}
        {/*                        onClick={() => {*/}
        {/*                            window.open('https://t.me/cdzv_com_support_bot', '_blank');*/}
        {/*                        }}*/}
        {/*                    >*/}
        {/*                        <MessageOutlined/> Contact via Telegram*/}
        {/*                    </Button>*/}
        {/*                    <Button*/}
        {/*                        type="primary"*/}
        {/*                        size="large"*/}
        {/*                        style={{*/}
        {/*                            // marginRight: '10px',*/}
        {/*                            width: '250px',*/}
        {/*                            background: '#631876',*/}
        {/*                            borderColor: '#0088cc',*/}
        {/*                            borderRadius: '8px',*/}
        {/*                            fontWeight: '500',*/}
        {/*                            // color: '#fff'*/}
        {/*                        }}*/}
        {/*                        onClick={() => {*/}
        {/*                            window.open('https://discord.com/invite/cu5tPqz4ft', '_blank');*/}
        {/*                        }}*/}
        {/*                    >*/}
        {/*                        <MessageOutlined/> Contact via Discord*/}
        {/*                    </Button>*/}
        {/*                    <Button*/}
        {/*                        type="primary"*/}
        {/*                        size="large"*/}
        {/*                        style={{*/}
        {/*                            // marginRight: '10px',*/}
        {/*                            width: '250px',*/}
        {/*                            background: '#631876',*/}
        {/*                            borderColor: '#0088cc',*/}
        {/*                            borderRadius: '8px',*/}
        {/*                            fontWeight: '500',*/}
        {/*                            // color: '#fff'*/}
        {/*                        }}*/}
        {/*                        onClick={() => {*/}
        {/*                            window.open('https://wa.me/380676792802', '_blank');*/}
        {/*                        }}*/}
        {/*                    >*/}
        {/*                        <MessageOutlined/> Contact via WhatsApp*/}
        {/*                    </Button>*/}
        {/*                    <Button*/}
        {/*                        type="primary"*/}
        {/*                        size="large"*/}
        {/*                        style={{*/}
        {/*                            // marginRight: '10px',*/}
        {/*                            width: '250px',*/}
        {/*                            background: '#631876',*/}
        {/*                            borderColor: '#0088cc',*/}
        {/*                            borderRadius: '8px',*/}
        {/*                            fontWeight: '500',*/}
        {/*                            // color: '#fff'*/}
        {/*                        }}*/}
        {/*                        onClick={() => {*/}
        {/*                            window.open('mailto:support@cdzv.com', '_blank');*/}
        {/*                        }}*/}
        {/*                    >*/}
        {/*                        <MailOutlined/> Contact via EMail*/}
        {/*                    </Button>*/}
        {/*                </Space>*/}
        {/*            ]}*/}
        {/*        >*/}
        {/*            <Image*/}
        {/*                style={{*/}
        {/*                    width: '100%',*/}
        {/*                    height: 'auto'*/}
        {/*                }}*/}
        {/*                src={'https://cdzv.com/strategy_img/Nichego_ne_ponyatno_Togda_zapisyvaisya_na_besplatnuyu_Zoom_konsultatsiyu_ot_CDZV_-_Recraft_2024-11-20_14-31-05.jpg'}*/}
        {/*                preview={false}*/}
        {/*            />*/}

        {/*        </Card>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*{blackFriday()}*/}

        <Components.CdzvPricingRU>
            <Override slot="text"/>
        </Components.CdzvPricingRU>
        {/*<Components.CdzvComparisonRU>*/
        }
        {/*    <Override*/
        }
        {/*        slot="text10"*/
        }
        {/*        lg-font="normal 500 20px/1.65 --fontFamily-sans"*/
        }
        {/*        md-font="normal 500 15px/1.4 --fontFamily-sans"*/
        }
        {/*        sm-font="15px/0.9 --fontFamily-serifGeorgia"*/
        }
        {/*        sm-margin="0px 0px 40px 0px"*/
        }
        {/*    />*/
        }
        {/*    <Override slot="text11" lg-font="normal 500 20px/1.65 --fontFamily-sans"*/
        }
        {/*              md-font="normal 500 15px/1.4 --fontFamily-sans" sm-font="15px/0.9 --fontFamily-serifGeorgia"/>*/
        }
        {/*    <Override*/
        }
        {/*        slot="text2"*/
        }
        {/*        lg-font="normal 500 20px/1.65 --fontFamily-sans"*/
        }
        {/*        md-font="normal 500 15px/1.4 --fontFamily-sans"*/
        }
        {/*        sm-font="15px/0.9 --fontFamily-serifGeorgia"*/
        }
        {/*        sm-text-align="center"*/
        }
        {/*        sm-margin="25px 0px 30px 0px"*/
        }
        {/*    />*/
        }
        {/*    <Override*/
        }
        {/*        slot="text3"*/
        }
        {/*        lg-font="normal 500 20px/1.65 --fontFamily-sans"*/
        }
        {/*        md-font="normal 500 15px/1.4 --fontFamily-sans"*/
        }
        {/*        sm-font="15px/0.9 --fontFamily-serifGeorgia"*/
        }
        {/*        sm-text-align="center"*/
        }
        {/*    />*/
        }
        {/*    <Override slot="text4" lg-font="normal 500 20px/1.65 --fontFamily-sans"*/
        }
        {/*              md-font="normal 500 15px/1.4 --fontFamily-sans" sm-font="15px/0.9 --fontFamily-serifGeorgia"/>*/
        }
        {/*    <Override*/
        }
        {/*        slot="text5"*/
        }
        {/*        lg-font="normal 500 20px/1.65 --fontFamily-sans"*/
        }
        {/*        md-font="normal 500 15px/1.4 --fontFamily-sans"*/
        }
        {/*        sm-font="15px/0.9 --fontFamily-serifGeorgia"*/
        }
        {/*        sm-margin="0px 0px 40px 0px"*/
        }
        {/*    />*/
        }
        {/*    <Override*/
        }
        {/*        slot="text6"*/
        }
        {/*        lg-font="normal 500 20px/1.65 --fontFamily-sans"*/
        }
        {/*        md-font="normal 500 15px/1.4 --fontFamily-sans"*/
        }
        {/*        sm-font="15px/0.9 --fontFamily-serifGeorgia"*/
        }
        {/*        sm-margin="0px 0px 40px 0px"*/
        }
        {/*    />*/
        }
        {/*    <Override*/
        }
        {/*        slot="text7"*/
        }
        {/*        lg-font="normal 500 20px/1.65 --fontFamily-sans"*/
        }
        {/*        md-font="normal 500 15px/1.4 --fontFamily-sans"*/
        }
        {/*        sm-font="15px/0.9 --fontFamily-serifGeorgia"*/
        }
        {/*        sm-margin="0px 0px 40px 0px"*/
        }
        {/*    />*/
        }
        {/*    <Override*/
        }
        {/*        slot="text8"*/
        }
        {/*        lg-font="normal 500 20px/1.65 --fontFamily-sans"*/
        }
        {/*        md-font="normal 500 15px/1.4 --fontFamily-sans"*/
        }
        {/*        sm-font="15px/0.9 --fontFamily-serifGeorgia"*/
        }
        {/*        sm-text-align="center"*/
        }
        {/*    />*/
        }
        {/*    <Override slot="text9" lg-font="normal 500 20px/1.65 --fontFamily-sans"*/
        }
        {/*              md-font="normal 500 15px/1.4 --fontFamily-sans" sm-font="15px/0.9 --fontFamily-serifGeorgia"/>*/
        }
        {/*    <Override slot="box3" sm-align-self="flex-start"/>*/
        }
        {/*    <Override slot="box13" sm-margin="0px 0px 14px 0px" md-margin="0px 0px 15px 0px"/>*/
        }
        {/*    <Override slot="box14" sm-margin="0px 0px 14px 0px" md-margin="0px 0px 15px 0px"/>*/
        }
        {/*    <Override slot="box25" sm-margin="0px 0px 14px 0px" md-margin="0px 0px 15px 0px"/>*/
        }
        {/*    <Override slot="box26" sm-margin="0px 0px 14px 0px" md-margin="0px 0px 15px 0px"/>*/
        }
        {/*</Components.CdzvComparisonRU>*/
        }
        {/*<Components.CdzvUsers background="--color-light" quarkly-title="Reviews">*/}
        {/*    <Override slot="box9"/>*/}
        {/*    <Override slot="box5"/>*/}
        {/*    <Override slot="box1"/>*/}
        {/*    <Override slot="box"/>*/}
        {/*    <Override slot="box21"/>*/}
        {/*</Components.CdzvUsers>*/}
        <Components.CdzvFaqRU/>
        <Components.CdzvFooterRU margin="0px 0px 0px 0px" background="--color-lightD1" padding="20px 0 40px 0">
            <Override slot="text2" display="none"/>
        </Components.CdzvFooterRU>
        <Components.QuarklycommunityKitCookieUsed margin="0px 0px 0px 0px" quarkly-title="CookieUsed"/>
        <Components.QuarklycommunityKitBackToTop padding="0px 0px 0px 0px" margin="0px 0px 0px 0px"
                                                 quarkly-title="BackToTop"/>
        <RawHtml>
            <style place={"endOfHead"} rawKey={"665dd05e1d712a0023346c52"}>
                {":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\nhtml {\nscroll-behavior: smooth;\n}\n"}
            </style>
        </RawHtml>
    </Theme>;
});