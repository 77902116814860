import React from 'react';
import {Table, Tag, Typography} from 'antd';
import {useMediaQuery} from 'react-responsive';
import {CheckCircleFilled, CloseCircleFilled} from '@ant-design/icons';

const {Title, Text} = Typography;

const fontSize = {
    mobile_lq: '9px',
    mobile: '12px',
    mobile_hr: '16px',
    tablet: '18px',
    tablet_hr: '22px',
    desktop: '28px',
}

const ComparisonTableUA = () => {
    const isMobileLQ = useMediaQuery({maxWidth: 350});
    const isMobile = useMediaQuery({maxWidth: 450});
    const isMobileHR = useMediaQuery({maxWidth: 767});
    const isTablet = useMediaQuery({minWidth: 768, maxWidth: 819});
    const isTabletHR = useMediaQuery({minWidth: 820, maxWidth: 1280});
    const isDesktop = useMediaQuery({minWidth: 1281});

    const columns = [
        {
            title: <Text strong style={{
                fontSize: isMobileLQ ? fontSize.mobile_lq : isMobile ? fontSize.mobile : isMobileHR ? fontSize.mobile_hr : isTablet ? fontSize.tablet : isTabletHR ? fontSize.tablet_hr : fontSize.desktop,
                fontWeight: 500
            }}>Функція</Text>,
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            width: '50%',
            render: (text) => <Text strong style={{
                fontSize: isMobileLQ ? fontSize.mobile_lq : isMobile ? fontSize.mobile : isMobileHR ? fontSize.mobile_hr : isTablet ? fontSize.tablet : isTabletHR ? fontSize.tablet_hr : fontSize.desktop
            }}>{text}</Text>,
        },
        {
            title: <Text strong style={{
                fontSize: isMobileLQ ? fontSize.mobile_lq : isMobile ? fontSize.mobile : isMobileHR ? fontSize.mobile_hr : isTablet ? fontSize.tablet : isTabletHR ? fontSize.tablet_hr : fontSize.desktop
            }}>Безкоштовно</Text>,
            dataIndex: 'free',
            key: 'free',
            align: 'center',
            render: (status) => {
                if (status === true) return <CheckCircleFilled style={{
                    color: '#00875a',
                    fontSize: isMobileLQ ? '16px' : isMobile ? '24px' : isMobileHR ? '28px' : isTablet ? '32px' : isTabletHR ? '36px' : '40px'
                }}/>;
                if (status === 'limited') return <Tag color="blue"
                                                      style={{
                                                          fontSize: isMobileLQ ? '12px' : isMobile ? '14px' : isMobileHR ? '16px' : isTablet ? '16px' : isTabletHR ? '18px' : '20px',
                                                          padding: '2px 6px'
                                                      }}>З обмеженнями</Tag>;
                return <CloseCircleFilled style={{
                    color: '#de350b',
                    fontSize: isMobileLQ ? '16px' : isMobile ? '24px' : isMobileHR ? '28px' : isTablet ? '32px' : isTabletHR ? '36px' : '40px'
                }}/>;
            },
        },
        {
            title: <Text strong style={{
                fontSize: isMobileLQ ? fontSize.mobile_lq : isMobile ? fontSize.mobile : isMobileHR ? fontSize.mobile_hr : isTablet ? fontSize.tablet : isTabletHR ? fontSize.tablet_hr : fontSize.desktop
            }}>Pro</Text>,
            dataIndex: 'pro',
            key: 'pro',
            align: 'center',
            render: (status) => (
                status ? <CheckCircleFilled style={{
                        color: '#00875a',
                        fontSize: isMobileLQ ? '16px' : isMobile ? '24px' : isMobileHR ? '28px' : isTablet ? '32px' : isTabletHR ? '36px' : '40px'
                    }}/> :
                    <CloseCircleFilled style={{
                        color: '#de350b',
                        fontSize: isMobileLQ ? '16px' : isMobile ? '24px' : isMobileHR ? '28px' : isTablet ? '32px' : isTabletHR ? '36px' : '40px'
                    }}/>
            ),
        },
    ];

    const data = [
        {key: '0', name: 'ГОТОВІ ДО ВИКОРИСТАННЯ СТРАТЕГІЇ', free: 'limited', pro: true},
        {key: '1', name: 'ПОБУДОВА СТРАТЕГІЙ', free: 'limited', pro: true},
        {key: '2', name: 'ПОБУДОВА GRID-СТРАТЕГІЙ', free: 'limited', pro: true},
        {key: '3', name: 'БЕКТЕСТУВАННЯ', free: 'limited', pro: true},
        {key: '4', name: 'АВТОМАТИЗАЦІЯ', free: 'limited', pro: true},
        {key: '5', name: 'УПРАВЛІННЯ РИЗИКАМИ', free: false, pro: true},
        {key: '6', name: 'МАШИНА СТАНІВ', free: false, pro: true},
        {key: '7', name: 'СЕСІЇ ТА ЧАС', free: true, pro: true},
        {key: '8', name: 'ПІРАМІДИНГ', free: false, pro: true},
        {key: '9', name: 'ЩОМІСЯЧНІ ЗВІТИ', free: false, pro: true},
        {key: '10', name: 'ВИПРОБУВАННЯ НА ЛІКВІДАЦІЮ', free: false, pro: true},
    ];

    return (
        <div
            style={{
                paddingTop: '10px',
                paddingBottom: '40px',
                paddingLeft: isMobileHR ? '0' : '100px',
                paddingRight: isMobileHR ? '0' : '100px',
                maxWidth: '100%',
                margin: '0 auto',
                backgroundColor: '#f9f9f9',
                borderRadius: '10px',
                // boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            }}
        >
            <Title level={2} style={{textAlign: 'center', fontSize: '48px', marginBottom: '10px'}}>
                Порівняння
            </Title>
            <Text type="secondary"
                  style={{textAlign: 'center', display: 'block', marginBottom: '30px', fontSize: '20px'}}>
                Порівняння функцій CDZV Toolkit різних версій
            </Text>
            <Table
                size={isMobile ? 'small' : 'middle'}
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                style={{backgroundColor: 'white', fontSize: '16px'}}
            />
        </div>
    );
};

export default ComparisonTableUA;
